@import "_variables";

.header-v1{
  padding: 7px 0!important;
  .logo-link{
    img{
      max-height: 75px;
    }
  }

  &.sticky{
    &.stuck{
      //menu when scrolled IF "sticky" set to yes in settings
      .main-navigation{
        li{
          a{
            //"stuck" link color
          }
        }
      }

    }
  }

  .animated {
    animation-duration: .5s
  }

  &.desktop-header{
    .action-buttons{
      margin-top: 0px;
      @media(max-width: 1200px){
        margin-top: 6px;
      }
    }
  }

  &.transparent{ //if NO color set
    &:not(.stuck){ //when the page hasn't been scrolled
      &.desktop-header .main-navigation a, &.mobile-header .button-container i, &.mobile-header .button-container span, &.mobile-header .button-container{
        //hamburger and main nav links (desktop)
        //color: #FFF;
        text-shadow: none!important;
      }
    }
  }


  /* DESKTOP NAV */
  @media(min-width:1001px){
    .main-navigation{
      margin-top: 25px;
        li{
          &.current_page_item{
            a{
              font-family: $header_font;
            }
          }
          &:hover{
            //transition: all ease-in-out 0.15s;
            .sub-menu {
                //transition: all ease-in-out;
            }
          }
          a{
            font-weight:700!important;
            text-transform: uppercase;
            text-align: left;
            font-size: 1.25rem;
            line-height: 1.25rem;
            padding: 0px 15px;
            color: $primary_color!important;
            font-family: $header_font;
            &:hover{
              //text-decoration: none;
              color:$body_text_color!important;
            }
          }
        }
        .sub-menu {
            background-color: #ffffff;
            padding-top: 10px;
            //min-width: 200px;
            //padding-left: 0;
            box-shadow: none;
            li{
              a{
                //width: 100%;
                padding: 4px 12px!important;
                font-size: 1.25rem;
                &:hover{
                  color: $primary_color!important;
                }
              }
            }
        }
    }
  }

  @media(max-width: 1200px){
    .main-navigation{
      margin-top: 10px;
    }
  }


  /* MOBILE NAVIGATION */
  .button-container,.overlay-button-container {
    //buttons for open and close
    color: $body_text_color;

    i, span{
      color: $body_text_color;
    }
  }

  .overlay-menu-container {
    //background: #FFF;
    nav {
      //padding: 50px 0;
    }
    ul {
      li {
        &.menu-item-has-children > a{
          font-family: $header_font;
          &:after{
            //font-family: FontAwesome;
            //content: "\f105";
            //width: 15px;
            //float: right;
          }
          &.open{
            &:after{
              //font-family: FontAwesome;
              //content: "\f107";
              //width: 15px;
              //float: right;
            }
          }
        }

        a {
          color: $body_text_color;
          font-family: $header_font;
        }
        .sub-menu{
          li{
            a{
              color: $body_text_color;
              font-family: $header_font;
            }
          }
        }
      }
    }

    .cta-buttons{
      //margin:2rem 0;
    }
  }
  /* END MOBILE NAVIGATION */

  .action-buttons{
    margin-top:7px!important;
    .btn{
      font-size: 1.4rem;
      &:hover{

      }
    }
  }
}
