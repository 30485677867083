@import "_variables";
html{
  font-size: 100%;
  @media(max-width: 767px){
    font-size: 90%;
  }
}
body{
    font-family: $global_font;
    position:relative;
    min-height: 75vh;
    height: 100%;
    padding-bottom: 50px;
}
.title-image-back{
  .tag{
    h1{
      font-size: 3rem;
    }
  }
}

.header-v1{
  &.desktop-header{
    .logo-link{
      margin-top: 20px;
      position: absolute!important;
      width: 200px;
      height: auto;
      
      img{
        width:200px;
        max-height: 100%!important;
      }
    }
    &.stuck{
    -webkit-box-shadow: 0px 5px 15px -1px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 5px 15px -1px rgba(0,0,0,0.35);
  box-shadow: 0px 5px 15px -1px rgba(0,0,0,0.35);
    }

    .main-navigation{
      margin-bottom: 20px;
    }
  }
}


.pr20{
  padding-right: 20px;
}
.btn{
  border-radius: 0!important;
}
p{
  font-size: 1.4rem;
}
h2{
  font-size: 2.4rem;
}
h3{
  font-size: 2rem;
}

.title-image-back{
  height: auto;
  padding: 35px 0;
  .tag{
    position: relative;
    left: 0;
  }
  &.dark {
    h1{
      color: $primary_color;
    }
  }
}

.full-screen-billboard{
  .container-title{
    position: absolute;
    bottom:0;
    left: 0;
    background: rgba(255,255,255,0.75);
    padding: 15px;
    width: 100%;
    h2{
      display: block!important;
      color: $primary_color;
      text-shadow: none;
      font-size: 2.25rem;
      padding-bottom: 0;
    }
    h3{
      font-size: 1.5rem;
      margin-left: 0!important;
    }
  }
}

.on-air-now-bar{
  background-color: #eeeeee;
    img{
      @media(max-width: 767px){
        margin: 15px 0;
      }
    }
    .desktop-pad{
      @media(min-width: 768px){
        padding-top: 20px;
        padding-bottom: 20px;
      }
      @media(min-width: 992px){
        padding-top: 10px;
        padding-bottom: 10px;
      }
      @media(min-width: 1199px){
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  @media(max-width: 767px){
    padding: 25px 0;
  }
}

.recent-news-events-snippet{
  border:2px #eeeeee solid;
  padding: 20px;
  h3{
    margin-top: 10px;
    font-size: 1.45rem;
    font-weight: bold;
  }
  p{
    font-size: 1.05rem;
    line-height: 1.25rem;
    margin: 0;
    &.date{
      font-size: .8rem;
      line-height: 1.25rem;
      font-style: italic;
      font-weight: bold;
      color: $body_text_color;
    }
  }
}
.on-air-block{
  background-color: #EEEEEE;
   transition: background-color 0.3s ease;
   margin-bottom: 25px;
  padding: 10px;
  img{
    @media(min-width:768px){
      object-fit: cover!important;
      height: 300px!important;
      object-position: 50% 0%!important;
    }
    width: 100%!important;
    
  }
  h2{
    font-size: 1.5rem;
    text-align: center;
    &:hover{
      text-decoration: none;
    }
  }
  &:hover{
    background-color: #d9d9d9;
     transition: background-color 0.3s ease;
     text-decoration: none;

  }
}
.post-info{
  h1{
    margin-bottom: 0;
  }
  p.date{
    font-size: 1.25rem;
    margin-bottom: 0;
    font-style: italic;
  }
}
.single-post{
  p{
    font-size: 1.25rem;
  }
}

.category-archive{
  h2{
    font-size: 1.75rem;
  }
  p{
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
    &.date{
      font-size: 1rem;
      line-height: 1.25rem;
      margin-bottom: 10px;
      font-style: italic;
      font-weight: bold;
      color: $body_text_color;
    }
  }
}

.vertical-ads{
  .ad{
    margin-bottom: 25px;
  }
}

footer.base-footer{
  position: absolute;
  bottom: -50px;
  width:100%;
  
}

